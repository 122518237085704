import React from 'react'
import PropTypes from 'prop-types'
import BackgroundImage from 'gatsby-background-image'

const PreviewCompatibleBgImage = ( props ) => {
  const {bgTag, imageInfo, bgClass, style} = props

  if (!!imageInfo && !!imageInfo.childImageSharp) {
    return (
      <BackgroundImage
      Tag={bgTag}
      className={bgClass}
      fluid={imageInfo.childImageSharp.fluid}
      style={style}
    >
      {props.children}
    </BackgroundImage>
    )
  }

  if (!!imageInfo.childImageSharp) {
    return (
      <div
      className={bgClass}
      style={{...style, backgroundImage: `url(${imageInfo})`}}
    >
      {props.children}
    </div>
    )
  }

  if (!!imageInfo && typeof imageInfo === 'string')
  return (
    <div
      className={bgClass}
      style={{...style, backgroundImage: `url(${imageInfo})`}}
    >
      {props.children}
    </div>
      )

  return null
}

PreviewCompatibleBgImage.propTypes = {
  imageInfo: PropTypes.shape({
    alt: PropTypes.string,
    childImageSharp: PropTypes.object,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    style: PropTypes.object,
  }).isRequired,
}

export default PreviewCompatibleBgImage
